import {leaveBreadcrumb, logWarning} from 'io/errors';
import services from 'services';
import {
	callSession,
	isCallSessionRinging,
	enioCallerCredentials,
} from 'modules/common/selectors';
import {enioCallDescriptions} from './enioCallActions';
import * as nActions from 'modules/notifications/actions';
import {medDur, longestDur} from 'constants/notifications';
import {browserPermissions} from 'utils/browserPermissions';
import {isPilotUser} from 'utils/perms';
import {
	CALL_STATUS_BUSY_HERE,
	CALL_STATUS_CALL_TERMINATED,
	CALL_STATUS_REQUEST_TERMINATED,
	CALL_STATUS_TEMPORARILY_UNAVAILABLE,
} from '../constants';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const postCallLog = ({
	clientId,
	buildingId,
	appName,
	//callMethod = null,
	startTime,
	endTime,
	...other
}) => {
	const apiData = {
		clientId,
		buildingId,
		appName,
		//callMethod,
		startTime,
		endTime,
		...other,
	};
	httpJson('post', '/callLogs', {}, {body: apiData}).catch(e => {
		logWarning(e);
	});
};

const postEnioCallLog = async (
	{store, client, building, appName, user},
	onClose = false,
) => {
	const callDetails = callSession(store.getState());
	const callDuration =
		callDetails?.endTime && callDetails?.answerTime
			? callDetails.endTime - callDetails.answerTime
			: null;

	const answerTime = callDetails.answerTime
		? new Date(callDetails.answerTime * 1000)
		: null;

	const endTime = callDetails.endTime ? new Date(callDetails.endTime * 1000) : null;

	const phone = client?.phone ? client?.phone : null;

	const callInfo = {
		clientId: client?.id,
		buildingId: building?.id,
		appName,
		startTime: new Date(callDetails.startTime * 1000),
		answerTime: answerTime,
		endTime: onClose ? new Date() : endTime,
		callDuration:
			onClose && callDetails?.answerTime
				? Number((Date.now() / 1000).toFixed(0)) - callDetails?.answerTime
				: callDuration,
		phone,
		callApp: 'enioCaller',
		meta: isPilotUser(user) ? {beta: true} : null,
	};
	onClose
		? localStorage.setItem('enioCallLog', JSON.stringify(callInfo))
		: postCallLog(callInfo);
};

const sipmlAudioElementId = 'audio-remote';

const getSingleton = (key, creator) =>
	new Promise((resolve, reject) => {
		if (!window[key]) {
			window[key] = {value: null, ready: false, failed: false, callbacks: null};
		}
		const store = window[key];
		if (store.ready) {
			resolve(store.value);
		} else if (store.failed) {
			reject(new Error(`Failed to create "${key}" singleton`));
		} else if (store.callbacks) {
			store.callbacks.push({resolve, reject});
		} else {
			store.callbacks = [{resolve, reject}];

			const resolveAll = value => {
				store.value = value;
				store.ready = true;
				for (const cb of store.callbacks) {
					cb.resolve(value);
				}
				store.callbacks = null;
			};
			const rejectAll = error => {
				store.failed = true;
				for (const cb of store.callbacks) {
					cb.reject(error);
				}
				store.callbacks = null;
			};

			creator(resolveAll, rejectAll);
		}
	});

// Wait for sipML library to be initialized. The library can only be initialized once, so avoid doing it multiple times.
const initSipmlClient = () =>
	getSingleton('sipmlInit', (resolve, reject) => {
		const readyCallback = () => {
			resolve();
		};
		const errorCallback = e => {
			console.error('sipML: Failed to initialize the engine: ' + e.message);
			reject(e);
		};
		window.SIPml.init(readyCallback, errorCallback);
	});

const getSipStackSingleton = (key, conf) =>
	getSingleton(key, resolve => {
		const stackEventsListener = function (e) {
			//console.info('Sipml stack event = ' + e.type);
			if (e.type === 'started') {
				login();
			}
		};
		const sipStack = new window.SIPml.Stack({
			enable_rtcweb_breaker: true, // optional
			events_listener: {events: '*', listener: stackEventsListener},
			ice_servers: '[]',
			...conf,
		});

		sipStack.start();

		let registerSession;
		const registerEventsListener = function (e) {
			//console.info('Sipml session event = ' + e.type);
			if (e.type === 'connected' && e.session === registerSession) {
				resolve(sipStack);
			}
		};
		const login = function () {
			registerSession = sipStack.newSession('register', {
				events_listener: {events: '*', listener: registerEventsListener},
			});
			registerSession.register();
		};
	});

/**
 * isCallActive is a 'hack' to prevent multiple calls when clicking too fast
 * The store values are not guaranteed to be updated when this function is executed in a quick succession.
 */
let isCallActive = false;

/**
 * @param {EnioCallClientParams} params
 */
export const enioCallClient = async ({client, building, user, appName}) => {
	if (localStorage.getItem('enioCallLog')) {
		postCallLog(JSON.parse(localStorage.getItem('enioCallLog')));
		localStorage.removeItem('enioCallLog');
	}
	const store = services.get('store');
	const checkIfCallIsActive = callSession(store.getState());

	// Prevent duplicate calling
	if (isCallActive || checkIfCallIsActive.active) {
		const callInProgressMsg = intl.formatMessage({
			id: 'Call is in progress. End earlier call first',
		});
		store.dispatch(
			nActions.warning({
				id: 'call-in-progress-end-earlier-call-first',
				message: callInProgressMsg,
				duration: medDur,
			}),
		);
		return;
	}

	// Mark call immediately active as otherwise we can start multiple calls
	isCallActive = true;

	const microphonePerm = await browserPermissions('microphone');
	if (!microphonePerm.status) {
		const permMsg =
			intl.formatMessage({id: 'microphone'}) +
			' ' +
			intl.formatMessage({id: microphonePerm.msg});
		store.dispatch(
			nActions.warning({
				id: 'invalid-enio-credentials',
				message: permMsg,
				duration: medDur,
			}),
		);
		isCallActive = false;
		return;
	}
	await initSipmlClient();
	let ringingLimit = 31; //seconds
	let isLogged = false;
	const credentials = enioCallerCredentials(store.getState());
	if (
		!credentials?.enioCallerUser ||
		!credentials?.enioCallerPassword ||
		!credentials?.domain
	) {
		const message = intl.formatMessage({id: 'Enio invalid credentials'});
		store.dispatch(
			nActions.warning({id: 'invalid-enio-credentials', message, duration: medDur}),
		);
		isCallActive = false;
		return;
	}

	const sipmlConf = {
		realm: credentials?.domain, // mandatory: domain name
		impi: `device.${credentials?.enioCallerUser}`, // mandatory: authorization name (IMS Private Identity)
		impu: `sip:device.${credentials.enioCallerUser}@${credentials?.domain}`, // mandatory: valid SIP Uri (IMS Public Identity)
		password: credentials?.enioCallerPassword, // optional
		display_name: user?.firstName + ' ' + user?.lastName, // optional
		websocket_proxy_url: 'wss://onecloud.setera.com', // optional
		//outbound_proxy_url: 'udp://example.org:5060', // optional
	};
	const sipStack = await getSipStackSingleton('sipStack', sipmlConf);
	const callData = {
		client,
		building,
		appName,
	};

	const callEventsListener = async e => {
		// Leave breadcrumbs about state transitions in case of call session error
		const eventDescriptionName = e?.description || 'unknown';
		leaveBreadcrumb(`SIPml.Session.Event.description: ${eventDescriptionName}`);
		leaveBreadcrumb(`SIPml.Session.Event.type: ${e?.type}`);

		if (typeof enioCallDescriptions[e.description] === 'function') {
			await enioCallDescriptions[e.description](e, store, callData);
		} else {
			const unexpectedErr =
				intl.formatMessage({id: 'Unexpected error'}) + ' - ' + e.description;
			store.dispatch(
				nActions.warning({
					id: 'unknown-enio-caller-error',
					message: unexpectedErr,
					duration: longestDur,
				}),
			);
			await enioCallDescriptions['unknown'](e, store, callData);
		}
		if (
			[
				CALL_STATUS_CALL_TERMINATED,
				CALL_STATUS_BUSY_HERE,
				CALL_STATUS_REQUEST_TERMINATED,
				CALL_STATUS_TEMPORARILY_UNAVAILABLE,
			].includes(e.description)
		) {
			// This is here because Busy Here causes status "Call terminated" afterwards
			// This prevents duplicate log posting this way
			if (!isLogged) {
				postEnioCallLog({store, client, building, appName, user});
				isLogged = true;
			}
		}

		switch (e.type) {
			case 'connected':
				isCallActive = true;
				break;
			case 'terminated':
				isCallActive = false;
				break;
			default:
				break;
		}
	};
	const enioCallSession = sipStack.newSession('call-audio', {
		audio_remote: document.getElementById(sipmlAudioElementId),
		events_listener: {events: '*', listener: callEventsListener},
	});
	const stackState = enioCallSession?.o_session?.o_stack?.e_state;
	// Stack is resetted. In some situations stack state is what it should not be. stackState is 4 when computer is set to sleep etc. Normally stack state should be 1.
	if (stackState === 4) {
		window['sipStack'] = undefined;
		enioCallClient({client, building, user, appName});
		isCallActive = false;
		return;
	}
	enioCallSession.o_session.media.b_100rel = true;
	leaveBreadcrumb('Start call [beta]');
	enioCallSession.call(client.phone);
	window.enioCallSession = enioCallSession;
	let ringingInterval;

	const startRingingInterval = () => {
		leaveBreadcrumb('Start ringing interval check');
		ringingInterval = setInterval(() => {
			const currentCallSession = callSession(store.getState());
			const isRinging = isCallSessionRinging(store.getState());
			if (currentCallSession?.startTime) {
				const stopRingingTime = currentCallSession?.startTime + ringingLimit;
				const currentTime = Math.floor(Date.now() / 1000);

				if (currentTime >= stopRingingTime) {
					leaveBreadcrumb(`Ringing time (${ringingLimit}s) exceeded`);
					// hangup the call when the ringing time has elapsed and the call is perceived as ringing
					if (isRinging) {
						leaveBreadcrumb(`Hangup call automatically`);
						enioCallSession.hangup();
						clearInterval(ringingInterval);
					} else {
						leaveBreadcrumb('Call is considered not ringing, clear interval');
						// Just clear interval
						clearInterval(ringingInterval);
					}
				}
			}
		}, 1000);
	};
	startRingingInterval();
	window.addEventListener('beforeunload', async e => {
		leaveBreadcrumb('beforeunload triggered');
		if (isCallActive) {
			e.preventDefault();
			e.returnValue =
				'Oletko varma, että haluat poistua? Puhelu sulkeutuu, jos suljet tämän ikkunan.';
		}
	});
	window.addEventListener('unload', e => {
		const call = callSession(store.getState());
		if (call.active) {
			postEnioCallLog({store, client, building, appName, user}, true);
		}
		hangup();
	});
};

/** Awful hack to allow users to always hangup calls
 * @type {import('../index.js').MarkCallNonActiveParams}
 * */
export const markCallNonActive = () => {
	isCallActive = false;
};

/**
 *
 * @type {import('../index.js').CanHangupParams}
 */
export const canHangup = () => {
	return window?.enioCallSession && typeof window?.enioCallSession?.hangup === 'function';
};

/**
 *
 * @type {import('../index.js').HangupParams}
 */
export const hangup = () => {
	window.enioCallSession.hangup();
};
