import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {convertSecondsToTimer} from 'utils/time';
import styled from 'styled-components';
import {applyDispatch, applyState} from 'utils/redux';
import {browserSettingsLink} from 'utils/browserSettingsLink';
import * as nActions from 'modules/notifications/actions';
import {shortDur} from 'constants/notifications';
import {callSession, deviceId, enioCallerVisible, user} from 'modules/common/selectors';
import {
	toggleEnioCallerVisibility,
	makeEnioCall,
	markCallSessionTerminatedByUser,
} from 'modules/common/actions';
import {compose} from 'ramda';
import {FormattedMessage} from '@meiko/react-intl';
import services from 'services';
import Button from 'components/generic/Button';
import {colors} from 'styles/constants';
import {isPilotUser} from 'utils/perms';
import {isValidPhoneNumber, normalizePhone} from 'utils/phone-number';
import {input} from 'styles/fragments';
import getEnioCallModule from 'io/eniocaller';
import {
	CALL_STATUS_BUSY_HERE,
	CALL_STATUS_CALL_TERMINATED,
	CALL_STATUS_FORBIDDED,
	CALL_STATUS_IN_CALL,
	CALL_STATUS_MEDIA_ADDED,
	CALL_STATUS_NOT_FOUND,
	CALL_STATUS_OK,
	CALL_STATUS_REQUEST_TERMINATED,
	CALL_STATUS_RINGING,
	CALL_STATUS_SESSION_PROGRESS,
	CALL_STATUS_TEMPORARILY_UNAVAILABLE,
	CALL_STATUS_TERMINATING,
	CALL_STATUS_TRYING,
} from 'io/eniocaller/constants';
const history = services.get('history');
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const busyhere = document.getElementById('busyhereBeta');
const callEndedAudio = document.getElementById('callended');
const callAudio = document.getElementById('audio-remote');

const CallContainer = styled.div`
	position: fixed;
	bottom: 20px;
	width: 600px;
	max-width: 100%;
	left: 50%;
	transform: translateX(-50%);
	height: 60px;
	background-color: white;
	color: black;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	border-radius: 10px;
	box-shadow: 0px 4px 8px -3px;
	z-index: 51;

	${props => {
		if (!props.visible) {
			return `
				left: calc(100%);
				transform: translateX(0%);
			`;
		}
	}}

	@media all and (max-width: 600px) {
		height: 120px;
		bottom: 0px !important;
		top: inherit !important;
	}
`;
const CallColumn = styled.div`
	position: relative;
	width: 100%;
	width: calc(100% - 120px);
	display: flex;
	flex-direction: column;

	@media all and (max-width: 600px) {
		order: 3;
		width: 100%;
	}
`;
const CallRow = styled.div`
	position: relative;
	width: calc(100% - 10px);
	padding: 0 0 0 10px;
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;

	&:first-child {
		border-bottom: 1px solid ${colors.grey3};
	}
`;
const CallIcon = styled.div`
	position: relative;
	width: 60px;
	height: 60px;
	font-size: 32px;
	color: white;
	background-color: ${props => props.bg};
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	${props => {
		return props.status === CALL_STATUS_RINGING
			? `
			animation: calling 4s infinite;

			@keyframes calling {
				0%,
				50%,
				100% {
					background-color: ${colors.grey3}
				}

				25%,
				75% {
					background-color: ${colors.success2}
				}
			}

		`
			: '';
	}}
	${props => {
		return props.customNumber === true
			? `
			cursor: pointer;
			background-color: ${
				props.isValidCustomNumber ? colors.success2 : colors.grey3
			} !important;
		`
			: '';
	}}
	@media all and (max-width: 600px) {
		border-radius: 0;
		width: 50%;
	}
`;
const CallIconButtonRounded = styled(Button)`
	width: 60px;
	height: 60px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 32px;
	${props => {
		return props.active
			? `background-color: ${colors.error2};`
			: `background-color: ${colors.grey3};`;
	}}
	${props => {
		return props.active ? 'cursor: pointer;' : '';
	}}
	color: white;
	border-radius: 0;

	@media all and (max-width: 600px) {
		border-radius: 0;
		width: 50%;
	}
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;

	&:hover {
		${props => {
			return props.active
				? `background-color: ${colors.error2} !important;`
				: `background-color: ${colors.grey3} !important;`;
		}}
		color: white !important;
	}
`;
const CallSettingsText = styled.p`
	position: absolute;
	margin: 0;
	right: 75px;
	top: 7px;
	cursor: pointer;
`;
const CallDetails = styled.p`
	position: relative;
	margin: 0;
`;
const CallDetailsLink = styled(CallDetails)`
	color: #419fc6;
	cursor: pointer;
`;
const CallDetailsLabel = styled.p`
	font-weight: bold;
	margin: 0;
`;
const NumpadInput = styled.input`
	${input}
	width: 100px;
	padding: 0px;
	display: flex;
	border-bottom: 1px solid ${colors.grey3};
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-radius: 1px;
	height: 20px;
	font-size: 12px;
	text-align: center;
	align-self: flex-end;
	position: absolute;
	right: 0px;
	bottom: 5px;
	:focus {
		border-bottom: 1px solid ${colors.grey3};
	}
`;

const SettingsRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 5px;

	> p {
		margin: 0;
		margin-right: 5px;
	}
`;

const callIconFunction = status => {
	let retVal;
	switch (status) {
		case CALL_STATUS_IN_CALL:
			retVal = colors.success2;
			break;
		case CALL_STATUS_MEDIA_ADDED:
			retVal = colors.success2;
			break;
		case CALL_STATUS_BUSY_HERE:
			retVal = colors.error2;
			break;
		default:
			retVal = colors.grey3;
			break;
	}
	return retVal;
};

const callStatusMessage = status => {
	let callStatusMsg = '';
	switch (status) {
		case CALL_STATUS_IN_CALL:
			callStatusMsg = 'Call duration';
			break;
		case CALL_STATUS_RINGING:
			callStatusMsg = 'Calling [waiting for answer]';
			break;
		case CALL_STATUS_TRYING:
			callStatusMsg = 'Connecting call';
			break;
		case CALL_STATUS_OK:
			// Use 'Connecting call' instead of 'Connection established' as for user it's irrelevent information
			callStatusMsg = 'Connecting call';
			break;
		case CALL_STATUS_MEDIA_ADDED:
			// Use 'Connecting call' instead of 'Connection established [call]' as for user it's irrelevent information
			callStatusMsg = 'Connecting call';
			break;
		case CALL_STATUS_BUSY_HERE:
			callStatusMsg = 'Busy [call in progress]';
			break;
		case CALL_STATUS_CALL_TERMINATED:
			callStatusMsg = 'Call terminated';
			break;
		case CALL_STATUS_TERMINATING:
			callStatusMsg = 'Call terminating...';
			break;
		case CALL_STATUS_NOT_FOUND:
			callStatusMsg = 'Phonenumber not found';
			break;
		case CALL_STATUS_REQUEST_TERMINATED:
			callStatusMsg = 'Request Terminated [calling]';
			break;
		case CALL_STATUS_TEMPORARILY_UNAVAILABLE:
			callStatusMsg = 'Temporarily Unavailable [calling]';
			break;
		default:
			callStatusMsg = 'No active call';
	}
	return callStatusMsg;
};

const EnioCallerSettings = styled.div`
	position: absolute;
	top: -${props => props.height}px;
	width: calc(100% - 118px);
	left: 0;
	right: 0;
	margin: 0 auto;
	height: ${props => props.height}px;
	background-color: white;
	border-top: 1px solid #e3e3e3;
	border-left: 1px solid #e3e3e3;
	border-right: 1px solid #e3e3e3;
	padding: 5px;
	display: flex;
	flex-direciton: row;
	flex-wrap: wrap;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	justify-content: center;

	> p {
		width: 100%;
		text-align: center;
		margin: 5px 0;
	}
	> input {
		margin: 0;
		width: 50%;
	}
	> button {
		width: 50%;
	}
`;

const copySettingToClipBoard = async value => {
	services.get('store').dispatch(
		nActions.success({
			id: 'clipboard-copied',
			duration: shortDur,
			message: services.get('intl').formatMessage({id: 'Link copied to clipboard'}),
		}),
	);
	navigator.clipboard.writeText(value);
};

const Settings = props => {
	const settingsLink = browserSettingsLink();
	const {volume, setVolume} = props;
	if (!settingsLink) {
		return (
			<EnioCallerSettings height="30">
				<p>
					<FormattedMessage id="Microphone setting does not exist" />
				</p>
			</EnioCallerSettings>
		);
	}
	return (
		<EnioCallerSettings height="85">
			<p>1. Kopioi linkki 2. Avaa uusi vällilehti 3. Liitä linkki osoitekenttään</p>
			<input value={settingsLink} readOnly="readonly"></input>
			<Button appearance="success" onClick={() => copySettingToClipBoard(settingsLink)}>
				<i className="fa fa-clipboard" aria-hidden="true"></i>&nbsp;
				<FormattedMessage id="Copy link" />
			</Button>

			<SettingsRow>
				<p>Äänenvoimakkuus</p>
				<input
					type="range"
					defaultValue={volume}
					min={0}
					max={100}
					step={5}
					onChange={e => {
						const newVolume = e.target.value / 100;
						setVolume(e.target.value);
						busyhere.volume = newVolume;
						callEndedAudio.volume = newVolume;
						callAudio.volume = newVolume;
					}}
				/>
				<p style={{width: '38px'}}>&nbsp;{volume} %</p>
			</SettingsRow>
		</EnioCallerSettings>
	);
};

const CheckBeta = props => {
	const {isPilot} = props;
	if (isPilot) return <>| Beta</>;
	return null;
};

const HideButton = styled.button`
	height: 40px;
	width: 20px;
	position: absolute;
	top: 10px;
	${props => {
		if (props.visible) {
			return 'right: -20px;';
		} else {
			return 'left: -20px;';
		}
	}}

	@media all and (max-width: 600px) {
		${props => {
			if (props.visible) {
				return 'right: 0px;';
			}
		}}
	}
`;

const HideButtonAction = e => {
	const parent = document.getElementById('callContainer');
	if (parent) {
		if (parent.hasAttribute('style')) {
			parent.removeAttribute('style');
		}
	}
};

const dragStop = e => {
	if (window.innerWidth < 650) {
		return;
	}
	if (
		e.pageX < 0 ||
		e.pageY < 0 ||
		e.pageX > window.innerWidth ||
		e.pageY > window.innerHeight
	) {
		return;
	}
	e.target.style.top = `${e.pageY}px`;
	e.target.style.left = `${e.pageX}px`;
};

const isMobileWidth = () => {
	if (window.innerWidth < 650) {
		return 'false';
	}
	return 'true';
};

const EnioCallerClient = props => {
	const {
		callSession,
		toggleEnioCallerVisibility,
		enioCallerVisible,
		user,
		makeEnioCall,
		markCallSessionTerminatedByUser,
	} = props;
	const [callTimer, setTimer] = useState(0);
	const [settingsVisible, toggleVisibility] = useState(false);
	const [customNumber, setCustomNumber] = useState('');
	const [customBtnState, setCustomBtnState] = useState(false);
	const [lastCustomNumber, setLastCustomNumber] = useState('');
	const [volume, setVolume] = useState(100);
	const isPilot = user ? isPilotUser(user) : false;

	const dragBoolean = isMobileWidth();

	useEffect(() => {
		if (window.innerWidth < 600) {
			toggleEnioCallerVisibility();
		}
	}, []);

	useEffect(() => {
		let timeoutId;
		if (callSession?.status === CALL_STATUS_RINGING && callTimer !== 0) {
			setTimer(0);
		}
		if (callSession?.status === CALL_STATUS_CALL_TERMINATED) {
			const callStopped = callSession?.endTime - callSession?.answerTime;
			if (callTimer !== callStopped && callTimer !== 0) {
				setTimer(callSession?.endTime - callSession?.answerTime);
			}
			clearTimeout(timeoutId);
			return;
		}
		if (callSession?.status === CALL_STATUS_IN_CALL) {
			timeoutId = setTimeout(() => {
				setTimer(callTimer + 1);
			}, 1000);
		}
	}, [callSession, callTimer]);

	useEffect(() => {
		if (callSession.status === CALL_STATUS_TRYING && customNumber.length > 0) {
			setCustomNumber('');
		} else if (
			callSession.status === CALL_STATUS_TRYING &&
			customNumber.length < 1 &&
			lastCustomNumber.length > 0
		) {
			setCustomNumber('');
			setLastCustomNumber('');
		}
	}, [callSession]);

	return (
		<CallContainer
			id="callContainer"
			draggable={settingsVisible ? 'false' : dragBoolean}
			onDragEnd={dragStop}
			visible={enioCallerVisible}
		>
			<HideButton
				visible={enioCallerVisible}
				onClick={() => {
					toggleEnioCallerVisibility();
					HideButtonAction();
				}}
			>
				{enioCallerVisible ? (
					<i className="fa fa-caret-right" aria-hidden="true"></i>
				) : (
					<i className="fa fa-caret-left" aria-hidden="true"></i>
				)}
			</HideButton>
			{/* prettier-ignore */}
			{!settingsVisible ? null : (
				<Settings volume={volume} setVolume={setVolume}></Settings>
			)}
			<CallIcon
				bg={callIconFunction(callSession.status)}
				status={callSession.status}
				customNumber={customNumber.length > 0 ? true : false}
				isValidCustomNumber={isValidPhoneNumber(customNumber)}
				onClick={() => {
					if (!isValidPhoneNumber(customNumber)) return;
					if (customBtnState) return;
					setLastCustomNumber(customNumber);
					makeEnioCall({user: user, number: customNumber});
					setCustomBtnState(true);
					setTimeout(() => {
						setCustomBtnState(false);
					}, 1000);
				}}
			>
				<i className="fa fa-phone" aria-hidden="true"></i>
			</CallIcon>
			<CallColumn>
				<CallRow>
					<CallDetailsLabel>
						<FormattedMessage id={callStatusMessage(callSession.status)} />
					</CallDetailsLabel>
					&nbsp;
					<CallDetails>
						{callSession?.status === CALL_STATUS_IN_CALL ||
						callSession?.status === CALL_STATUS_TERMINATING ||
						callSession?.status === CALL_STATUS_CALL_TERMINATED ? (
							<>{convertSecondsToTimer(callTimer)}</>
						) : null}
					</CallDetails>
				</CallRow>
				<CallRow>
					{(callSession?.status === CALL_STATUS_MEDIA_ADDED ||
						callSession?.status === CALL_STATUS_CALL_TERMINATED ||
						callSession?.status === CALL_STATUS_TERMINATING ||
						callSession?.status === CALL_STATUS_BUSY_HERE ||
						callSession?.status === CALL_STATUS_REQUEST_TERMINATED ||
						callSession?.status === CALL_STATUS_TEMPORARILY_UNAVAILABLE ||
						callSession?.status === CALL_STATUS_SESSION_PROGRESS ||
						callSession?.status === CALL_STATUS_IN_CALL ||
						callSession?.status === CALL_STATUS_RINGING) &&
					lastCustomNumber.length < 1 ? (
						<CallDetailsLink
							// This title needs translation
							title="Asiakkaan tiedot"
							onClick={() => {
								history.push(callSession.customerURI);
							}}
						>
							<i className="fa fa-user" aria-hidden="true"></i>&nbsp;
							{callSession?.customerDetails?.client?.firstName}{' '}
							{callSession?.customerDetails?.client?.lastName}
							{' - '}
							{callSession?.customerDetails?.building?.address}
							{', '}
							{callSession?.customerDetails?.building?.city}
						</CallDetailsLink>
					) : null}
					{(callSession?.status === CALL_STATUS_MEDIA_ADDED ||
						callSession?.status === CALL_STATUS_CALL_TERMINATED ||
						callSession?.status === CALL_STATUS_TERMINATING ||
						callSession?.status === CALL_STATUS_BUSY_HERE ||
						callSession?.status === CALL_STATUS_REQUEST_TERMINATED ||
						callSession?.status === CALL_STATUS_TEMPORARILY_UNAVAILABLE ||
						callSession?.status === CALL_STATUS_FORBIDDED ||
						callSession?.status === CALL_STATUS_RINGING) &&
					lastCustomNumber.length > 0 ? (
						<CallDetailsLink>{lastCustomNumber}</CallDetailsLink>
					) : null}

					<NumpadInput
						id="enioNumpad"
						type="text"
						value={customNumber}
						isValid={customNumber.length > 0 ? isValidPhoneNumber(customNumber) : true}
						placeholder={intl.formatMessage({id: 'Phone number'})}
						onChange={e => {
							const normalizedPhone = normalizePhone(e.target.value);

							if (normalizedPhone < 1) {
								setCustomNumber(normalizedPhone);
								return;
							}
							if (!/^\+?\d*$/.test(normalizedPhone)) return;
							setCustomNumber(normalizedPhone);
						}}
					></NumpadInput>
				</CallRow>
			</CallColumn>

			<CallSettingsText onClick={() => toggleVisibility(!settingsVisible)}>
				<FormattedMessage id="Settings" /> &nbsp;
				<CheckBeta isPilot={isPilot} />
			</CallSettingsText>

			<CallIconButtonRounded
				active={callSession?.active}
				onClick={() => {
					const enioModule = getEnioCallModule(isPilotUser(user));
					enioModule.markCallNonActive();
					if (enioModule.canHangup()) {
						markCallSessionTerminatedByUser();
						enioModule.hangup();
					}
				}}
			>
				<i className="fa fa-times" aria-hidden="true"></i>
			</CallIconButtonRounded>
		</CallContainer>
	);
};

export default compose(
	withRouter,
	connect(
		applyState({callSession, deviceId, enioCallerVisible, user}),
		applyDispatch({
			toggleEnioCallerVisibility,
			makeEnioCall,
			markCallSessionTerminatedByUser,
		}),
	),
)(EnioCallerClient);
