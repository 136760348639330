//@ts-check

import * as stable from 'io/eniocaller/stable/calls';
import * as beta from 'io/eniocaller/beta/calls';

// Typedefs
/**
 * @typedef {{client: object, building: object, user: object, appName: string}} EnioCallClientParams
 * @returns {Promise<void>}
 */

/**
 * @typedef {function(): void} MarkCallNonActiveParams
 */

/**
 * @typedef {function(): boolean} CanHangupParams
 */

/**
 * @typedef {function(): void} HangupParams
 */

/**
 * @typedef EniocallerModule
 * @property {MarkCallNonActiveParams} markCallNonActive Marks the call non active.
 * @property {function} enioCallClient The enioCallClient.
 * @property {CanHangupParams} canHangup - Whether the call can be hung up.
 * @property {HangupParams} hangup - Hangup the call.
 */

/**
 * "Dynamically" import the correct client based on the environment.
 *
 * We cannot actually use dynamic imports as the callClient is sometimes resolved in effect-handler which do not support async functions.
 *
 * @param {boolean} isPilot - Whether the user is a pilot user or not.
 * @returns {EniocallerModule} The correct (beta/stable) eniocaller module.
 */
const getModule = isPilot => {
	return isPilot ? beta : stable;
};

export default getModule;
