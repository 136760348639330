import {setCallSession} from 'modules/common/actions';
import {callSession} from 'modules/common/selectors';
import {logWarning, logError} from 'io/errors';
import * as nActions from 'modules/notifications/actions';
import services from 'services';
import {longDur} from 'constants/notifications';
import {hangup, canHangup} from './calls';
import {
	CALL_STATUS_CALL_IN_PROGRESS,
	CALL_STATUS_RINGING,
	CALL_STATUS_OK,
	CALL_STATUS_IN_CALL,
	CALL_STATUS_MEDIA_ADDED,
	CALL_STATUS_CALL_TERMINATED,
	CALL_STATUS_TERMINATING,
	CALL_STATUS_BUSY_HERE,
	CALL_STATUS_REQUEST_TERMINATED,
	CALL_STATUS_NOT_FOUND,
	CALL_STATUS_SESSION_PROGRESS,
	CALL_STATUS_TEMPORARILY_UNAVAILABLE,
	CALL_STATUS_FORBIDDED,
	CALL_STATUS_CALL_IS_BEING_FORWARDED,
	CALL_STATUS_SIP_ERROR,
	CALL_STATUS_DOES_NOT_EXIST_ANYWHERE,
	CALL_STATUS_CONCURRENT_THIRD_PARTY,
	CALL_STATUS_NOT_ACCEPTABLE,
	CALL_STATUS_SERVER_TIMEOUT,
	CALL_STATUS_TRANSPORT_ERROR,
	CALL_STATUS_ADDRESS_INCOMPLETE,
	CALL_STATUS_TEMPORARILY_NOT_AVAILABLE,
	CALL_STATUS_UNKOWN,
} from '../constants';

const busyhere = document.getElementById('busyhereBeta');
const callEndedAudio = document.getElementById('callended');

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const PLAY_ACTION = 'play';
const PAUSE_ACTION = 'pause';
// Safely interact with audio elements.
// Prevents page crashing if audio elements are started/stopped due to autoplay restrictions (see: https://developer.chrome.com/blog/autoplay/)
const handleAudioAction = async (el, action) => {
	try {
		await el[action]();
	} catch (e) {
		//
	}
};
const playAudio = el => handleAudioAction(el, PLAY_ACTION);
const pauseAudio = el => handleAudioAction(el, PAUSE_ACTION);

const showError = (callDesc, store) => {
	const msg = intl.formatMessage({id: 'Handled eniocaller event'});
	const stateMsg = intl.formatMessage({id: callDesc + ' [calling]'});
	const notification = nActions.warning({
		id: 'handled-enio-error',
		message: msg + ' - ' + stateMsg,
		duration: longDur,
	});
	store.dispatch(notification);
};

const generalErrorHandle = async (e, store, errorMsg = null) => {
	// Report every general error to bugsnag
	const sipStateError = new Error('Call session error - ' + e?.description);
	logError(sipStateError);

	const callStatus = callSession(store.getState());
	const retVal = {
		...callStatus,
		status: e.description,
		active: false,
	};
	store.dispatch(setCallSession(retVal));
	const msg = errorMsg !== null ? errorMsg : e.description;
	showError(msg, store);
	callEndedAudio.currentTime = 0;
	playAudio(callEndedAudio);
	if (canHangup()) {
		hangup();
	}
};

export const enioCallDescriptions = {
	[CALL_STATUS_CALL_IN_PROGRESS]: async (e, store, callData = {}) => {},
	Trying: async (e, store, callData = {}) => {
		//console.log('Yhdistetään verkkoon...');
		// When call status is "Trying", call needs to have property "active" set to true to prevent duplicate calls
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: Math.floor(Date.now() / 1000),
			answerTime: null,
			endTime: null,
			customerURI: window.location.pathname + window.location.search,
			customerDetails: callData,
		};
		store.dispatch(setCallSession(retVal));
		busyhere.currentTime = 0;
		return retVal;
	},
	[CALL_STATUS_RINGING]: async (e, store, callData = {}) => {
		//console.log('Soittaa...');
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		await busyhere.pause();
		return retVal;
	},
	[CALL_STATUS_OK]: async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		//console.log('Puhelu yhdistetty');
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus?.startTime ? callStatus?.startTime : null,
			answerTime: null,
			endTime: null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	// Call is in
	[CALL_STATUS_IN_CALL]: async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		//console.log('Puhelussa');
		let retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus.startTime,
			answerTime: Math.floor(Date.now() / 1000),
			endTime: null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		//console.log(callStatus);
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	// Proper connection is made
	[CALL_STATUS_MEDIA_ADDED]: async (e, store, callData = {}) => {
		//console.log('Media lisätty');
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus.startTime,
			answerTime: callStatus.answerTime,
			endTime: callStatus?.endTime ? callStatus?.endTime : null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	// Call terminated = Client has terminated the call
	[CALL_STATUS_CALL_TERMINATED]: async (e, store, callData = {}) => {
		//console.log('Puhelu päättyi');
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: callStatus.answerTime,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		if (callStatus.status !== CALL_STATUS_TERMINATING) {
			callEndedAudio.currentTime = 0;
			playAudio(callEndedAudio);
		}
		return retVal;
	},
	// Call terminating... = User has terminated the phone
	[CALL_STATUS_TERMINATING]: async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: callStatus.answerTime,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	// Busy Here = Client does not want to answer or line is in use
	[CALL_STATUS_BUSY_HERE]: async (e, store, callData = {}) => {
		//console.log('Varattu');
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		playAudio(busyhere);
		setTimeout(async () => {
			pauseAudio(busyhere);
		}, 2500);
		return retVal;
	},
	[CALL_STATUS_REQUEST_TERMINATED]: async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	[CALL_STATUS_NOT_FOUND]: async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		playAudio(busyhere);
		setTimeout(async () => {
			pauseAudio(busyhere);
		}, 5500);
		return retVal;
	},
	[CALL_STATUS_SESSION_PROGRESS]: async (e, store, client = {}) => {
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	[CALL_STATUS_TEMPORARILY_UNAVAILABLE]: async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	[CALL_STATUS_FORBIDDED]: async (e, store, callData = {}) =>
		await generalErrorHandle(e, store),

	[CALL_STATUS_CALL_IS_BEING_FORWARDED]: async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		const retVal = {
			...callStatus,
			status: e.description,
		};
		store.dispatch(setCallSession(retVal));
	},
	[CALL_STATUS_SIP_ERROR]: async (e, store, callData = {}) =>
		await generalErrorHandle(e, store, 'SIP error'),
	[CALL_STATUS_DOES_NOT_EXIST_ANYWHERE]: async (e, store, callData = {}) =>
		await generalErrorHandle(e, store),
	[CALL_STATUS_CONCURRENT_THIRD_PARTY]: async (e, store, callData = {}) =>
		await generalErrorHandle(e, store),
	[CALL_STATUS_NOT_ACCEPTABLE]: async (e, store, callData = {}) =>
		await generalErrorHandle(e, store),
	[CALL_STATUS_SERVER_TIMEOUT]: async (e, store, callData = {}) =>
		await generalErrorHandle(e, store),
	[CALL_STATUS_TRANSPORT_ERROR]: async (e, store, callData = {}) =>
		await generalErrorHandle(e, store),
	[CALL_STATUS_ADDRESS_INCOMPLETE]: async (e, store, callData = {}) =>
		await generalErrorHandle(e, store),
	[CALL_STATUS_TEMPORARILY_NOT_AVAILABLE]: async (e, store, callData = {}) =>
		await generalErrorHandle(e, store),

	[CALL_STATUS_UNKOWN]: async (e, store, callData = {}) => {
		// This include check is here because handling SIP error would require use of regex and it is very hard to implement this kind of system to function calls
		// That is why we just check that if event description contains this kind of string then we run sipError function.
		if (e?.description.includes(`can't be found in`)) {
			enioCallDescriptions.sipError(e, store, callData);
			return;
		}
		const callDetails = callSession(store.getState());
		// We set call active status to false just in case. It exposes system to possible duplicate calls, but
		// it wont prevent user from continuing calling after this. We do not want to terminate call when
		// unknown state happends because it could happen in middle of call.
		const retVal = {
			...callDetails,
			active: false,
			errors: {
				eventName: e?.description,
			},
		};
		logWarning(new Error('Unknown call event happened - ' + e?.description));
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
};
