export const CALL_STATUS_TRYING = 'Trying';
export const CALL_STATUS_CALL_IN_PROGRESS = 'Call in progress...';
export const CALL_STATUS_RINGING = 'Ringing';
export const CALL_STATUS_OK = 'OK';
export const CALL_STATUS_IN_CALL = 'In Call';
export const CALL_STATUS_MEDIA_ADDED = 'Media Added';
export const CALL_STATUS_CALL_TERMINATED = 'Call terminated';
export const CALL_STATUS_TERMINATING = 'Call terminating...';
export const CALL_STATUS_BUSY_HERE = 'Busy Here';
export const CALL_STATUS_REQUEST_TERMINATED = 'Request Terminated';
export const CALL_STATUS_NOT_FOUND = 'Not Found';
export const CALL_STATUS_SESSION_PROGRESS = 'Session Progress';
export const CALL_STATUS_TEMPORARILY_UNAVAILABLE = 'Temporarily Unavailable';
export const CALL_STATUS_FORBIDDED = 'Forbidden';
export const CALL_STATUS_CALL_IS_BEING_FORWARDED = 'Call Is Being Forwarded';
export const CALL_STATUS_SIP_ERROR = 'sipError';
export const CALL_STATUS_DOES_NOT_EXIST_ANYWHERE = 'Does Not Exist Anywhere';
export const CALL_STATUS_CONCURRENT_THIRD_PARTY =
	'Concurrent third party phone calls exceeds configured value for user';
export const CALL_STATUS_NOT_ACCEPTABLE = 'Not Acceptable';
export const CALL_STATUS_SERVER_TIMEOUT = 'Server Time-out';
export const CALL_STATUS_TRANSPORT_ERROR = 'Transport error';
export const CALL_STATUS_ADDRESS_INCOMPLETE = 'Address Incomplete';
export const CALL_STATUS_TEMPORARILY_NOT_AVAILABLE = 'Temporarily not available';
export const CALL_STATUS_UNKOWN = 'unknown';
